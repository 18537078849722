import React, { useCallback, useMemo, useState } from "react";

import { Button, CarImageGallery, CarStatusBadge, Pill, TestDriveBooking } from "@/components";
import { useComparisonCarsIds, useMediaQuery, useQueryParamsState } from "@/hooks";
import { cn } from "@/utils";

import { Modal } from "../modal/Modal";
import { CarModelVersionPicker, ModelVersionSelectAccordion } from "./CarVariantPicker";
import { CAR_DISPLAY_STATUS } from "@/const";

import {
  CarDetailsAttributeList,
  CarDetailsPropertyList,
  CarDetailsSpecList,
} from "./DataPresentation";

const LG_SCREEN_MEDIA_QUERY = "(min-width: 992px)";

/**
 * Car details component
 * @param {Object} props
 * @param {import('@/api/generated/cars').CarDetailSchema[]} props.variants
 */
export const CarDetails = ({ variants }) => {
  const isDesktop = useMediaQuery(LG_SCREEN_MEDIA_QUERY);
  const isMobile = !isDesktop;
  const [selectedModelVersion, setSelectedModelVersion] = useState(null);
  const [selectedSwatch, setSelectedSwatch] = useState(variants?.[0]?.swatch);
  const [carVariantPickerMode, setCarVariantPickerMode] = useQueryParamsState("type", "purchase");
  const { comparisonCarsIds, setComparisonCarsIds } = useComparisonCarsIds();

  const selectedVariant = useMemo(() => {
    return (
      variants.find(
        (v) => v.model_version === selectedModelVersion && v.swatch === selectedSwatch,
      ) ?? variants[0]
    );
  }, [selectedModelVersion, selectedSwatch, variants]);

  const imagesForSelectedModelVersion = useMemo(() => {
    return variants.filter((v) => v.model_version === selectedModelVersion);
  }, [selectedModelVersion, variants]);

  const selectedVariantInComparison = useMemo(
    () => comparisonCarsIds.includes(selectedVariant.id),
    [comparisonCarsIds, selectedVariant],
  );

  const handleComparisonToggle = useCallback(() => {
    setComparisonCarsIds((ids) => {
      if (selectedVariantInComparison) {
        return ids.filter((id) => id !== selectedVariant.id);
      }
      return [...ids, selectedVariant.id];
    });
  }, [selectedVariant, selectedVariantInComparison, setComparisonCarsIds]);

  return (
    <div className="car-details">
      <div className="car-details__content">
        <div className="car-details__section">
          {isMobile && (
            <h1 className="mb-3">
              <span className="car-details__make">{selectedVariant.make}</span>
              <br />
              <span className="car-details__model">{selectedVariant.model}</span>
            </h1>
          )}
          <div className="d-flex justify-content-between flex-wrap">
            <label className="car-details__comparison-toggle">
              <input
                type="checkbox"
                checked={selectedVariantInComparison}
                onChange={handleComparisonToggle}
              />
              <span>Setja í samanburð</span>
            </label>
            <div className="d-flex gap-2 align-items-center justify-content-center mb-3">
              <CarStatusBadge status={selectedVariant.display_status} />
              {selectedVariant.kjarabill && <CarStatusBadge status={CAR_DISPLAY_STATUS.KJARABILL} />}
              {selectedVariant.discounts.some((d) => d.code === "energy_grant") && (
                <Pill label="Orkusjóðsstyrkur" color="green" />
              )}
              {selectedVariant.discounts.some((d) => d.code === "offer_price") && (
                <CarStatusBadge status={CAR_DISPLAY_STATUS.MODEL_ON_OFFER} />
              )}
            </div>
          </div>
          <CarImageGallery
            variants={imagesForSelectedModelVersion}
            onSwatchChange={setSelectedSwatch}
            compact={isMobile}
            className="mb-4 mb-lg-0"
          />
          {isMobile && (
            <>
              <ModelVersionSelectAccordion
                variants={variants}
                selectedModelVersion={selectedModelVersion}
                onSelectModelVersion={setSelectedModelVersion}
                rental={carVariantPickerMode === "rental"}
                className="mb-4"
              />
              {selectedVariant.model_description && (
                <p className="m-0">{selectedVariant.model_description}</p>
              )}
            </>
          )}
        </div>
        <div className="car-details__section">
          <div className="car-details__disclaimer">
            <p className="mb-0">
              Birt með fyrirvara um villur og breytingar. BL ehf. áskilur sér rétt til að breyta
              verði og búnaði án fyrirvara. Athugið að búnaður á myndum getur verið ólíkur
              staðalbúnaði bílsins.
            </p>
          </div>
        </div>
        <div className="car-details__section">
          <div className="car-details__section__title-wrapper">
            <h4 className="car-details__section__title">Tækniupplýsingar</h4>
          </div>
          <CarDetailsSpecList car={selectedVariant} />
        </div>
        <div className="car-details__section">
          <div className="car-details__section__title-wrapper">
            <h4 className="car-details__section__title">
              {selectedVariant?.variant ? (
                <span>Búnaður í {selectedVariant.variant}</span>
              ) : (
                <span>Búnaður</span>
              )}
            </h4>
          </div>
          <CarDetailsAttributeList categories={selectedVariant?.attributes} />
        </div>
        <div className="car-details__section">
          <div className="car-details__section__title-wrapper">
            <h4 className="car-details__section__title">Nánari upplýsingar</h4>
          </div>
          <CarDetailsPropertyList variant={selectedVariant} />
        </div>
      </div>
      {isDesktop && (
        <div className="car-details__sidebar">
          <h1 className="mb-3">
            <span className="car-details__make">{selectedVariant.make}</span>
            <br />
            <span className="car-details__model">{selectedVariant.model}</span>
          </h1>
          {selectedVariant.model_description && <p>{selectedVariant.model_description}</p>}
          <CarModelVersionPicker
            variants={variants}
            selectedVariant={selectedVariant}
            selectedModelVersion={selectedModelVersion}
            onSelectModelVersion={setSelectedModelVersion}
            mode={carVariantPickerMode}
            onModeChange={setCarVariantPickerMode}
            className="mb-5"
          />
        </div>
      )}
      {isMobile && (
        <CarModelVersionPicker
          variants={variants}
          selectedVariant={selectedVariant}
          selectedModelVersion={selectedModelVersion}
          onSelectModelVersion={setSelectedModelVersion}
          mode={carVariantPickerMode}
          onModeChange={setCarVariantPickerMode}
          compact
        />
      )}
    </div>
  );
};

/**
 * @param {Object} props
 * @param {import("@/api/generated/cars").CarDetailSchema} props.car - Car to book a test drive for
 * @param {boolean} props.compact - Mobile or not
 */
export const CarTestDriveBooking = ({ car, compact }) => {
  const [showBookingForm, setShowBookingForm] = useState(false);
  return (
    <div className={cn("car-details__section", compact && "w-50")}>
      <div className="mt-lg-2 mt-0">
        {car.display_status !== "IN_ORDER" && <Button
          onClick={() => setShowBookingForm(true)}
          label={compact ? "Reynsluakstur" : "Bóka reynsluakstur"}
          className={cn(
            "w-100 fs-5 fw-bold bl-blue capitalize",
            showBookingForm && "d-none",
            compact ? "button--large" : "button--CTA",
          )}
        />}
      </div>
      <Modal
        isOpen={showBookingForm}
        onRequestClose={() => setShowBookingForm(false)}
        contentLabel="Bóka reynsluakstur"
        className="car-details__booking p-5"
      >
        <TestDriveBooking car={car} onCloseBookingForm={() => setShowBookingForm(false)} />
      </Modal>
    </div>
  );
};
